<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-title>My Bank Happiness Account</ion-title>
        <Logout slot="end" />
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Tab 2</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-grid :fixed="true" class="flex">
        <ion-row class="ion-align-items-center w-full">
          <ion-col size="12" class="ion-text-center">
            <ion-item v-for="item in adminList" v-bind:key="item.id">
              <ion-label>
                {{item.email}}
              </ion-label>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/vue';
import { IonItem, IonLabel } from '@ionic/vue';
import { IonGrid, IonRow, IonCol } from '@ionic/vue';
import { defineComponent } from 'vue';
import { onIonViewWillEnter } from '@ionic/vue';
import Logout from '@/components/Logout.vue';
import libStore from "@/lib/libStore";
import loader from "@/lib/loader";

export default defineComponent({
  name: 'Tab2',
  components: {
    Logout,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel
  },
  setup () {
    const { loading } = loader()
    const { adminList, goToLogin, getAdminList } = libStore();
    onIonViewWillEnter(() => {
      goToLogin();
      getAdminList();
    });
    return {
      adminList,
      loading
    }
  }
});
</script>