
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/vue';
import { IonItem, IonLabel } from '@ionic/vue';
import { IonGrid, IonRow, IonCol } from '@ionic/vue';
import { defineComponent } from 'vue';
import { onIonViewWillEnter } from '@ionic/vue';
import Logout from '@/components/Logout.vue';
import libStore from "@/lib/libStore";
import loader from "@/lib/loader";

export default defineComponent({
  name: 'Tab2',
  components: {
    Logout,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel
  },
  setup () {
    const { loading } = loader()
    const { adminList, goToLogin, getAdminList } = libStore();
    onIonViewWillEnter(() => {
      goToLogin();
      getAdminList();
    });
    return {
      adminList,
      loading
    }
  }
});
